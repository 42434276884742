@use '../../src/styles/mixins/colours';

.DisabilityAdvocacyFinder {
    margin-bottom: 50px;
    container-type: inline-size; /* stylelint-disable-line property-no-unknown */

    .SearchBar {
        .Input input {
            border: #{colours.get-static('colour-border-standard-callout')} 1px solid;
            border: var(--colour-border-standard-callout) 1px solid;
        }
    }

    h2 {
        text-align: center;
    }

    hr {
        border: 0;
        border-top: 2px solid #000;
        margin: 0 auto;
        width: 100%;
        padding-bottom: 20px;
    }

    h3 {
        margin-bottom: 0;
        text-align: center;
    }

    ul {
        list-style-type: none;
        padding: 0;
        text-align: center;
    }

    li {
        margin-bottom: 5px;
    }

    .disabilityHeader {
        text-align: center;
        margin-top: 20px;
    }

    .disabilityButton {
        background-color: #005568;
        color: #fff;
        border: 0;
        padding: 15px 30px;
        font-size: 22px;
        width: 67%;
        cursor: pointer;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 8px;
        height: 65px;
    }

    .disabilityButton.static {
        cursor: default;
    }

    .orText {
        font-size: 18px;
        font-weight: bold;
        margin: 20px 0;
    }

    .connectedButtons {
        width: 67%;
        margin: 0 auto;
        padding: 0;
        padding-bottom: 20px;
    }

    .disabilitySubButton {
        background-color: #bebfc7;
        color: #000;
        border: 0;
        padding: 15px 30px;
        font-size: 17px;
        width: 100%;
        cursor: pointer;
        display: block;
        font-weight: bold;
        text-align: left;
        border-radius: 0;
        height: 75px;
        margin: 0;
        outline: 0;
    }

    .disabilitySubButton:hover {
        background-color: #8a8b93;
        color: #fff;
    }

    .divider {
        height: 1.2px;
        background-color: #000;
        margin: 0;
    }

    .socialserviceslist {
        padding: -10px;
        width: 70%;
        margin: 0 auto;
    }

    .de-emphasised {
        color: #{colours.get-static('colour-text-mid')};
        color: var(--colour-text-mid);
        margin: 0.5em 0;
    }

    .askIzzyInfoBox {
        margin-top: 40px;
        padding: 0 1.2em 1em;
        border: #{colours.get-static('colour-border-standard-callout')} 1px solid;
        border: var(--colour-border-standard-callout) 1px solid;
        border-radius: 5px;
        text-align: center;

        .allServicesLink {
            margin-top: 1em;
        }
    }

    .backToDSS {
        margin: 2em 0;
        display: flex;
        justify-content: center;
        font-weight: bold;
        text-align: center; // keeps second line of text centred if it breaks into multiple lines

        // For text to break in a more aesthetically pleasing way. Compatibility is sketchy but
        // this is a progressive enhancement so it doesn't matter.
        // When container unit's become a thing we can do this nicer with cqw to
        // break like when it hit's 100% of width:
        // https://github.com/w3c/csswg-drafts/issues/5888
        @container (max-width: 370px) { /* stylelint-disable-line scss/at-rule-no-unknown */
            margin: 2em 10vw;
        }

        .Link {
            text-decoration: none;
            color: #{colours.get-static('colour-text-dark')};
            color: var(--colour-text-dark);
        }

        &:hover {
            .SvgIcon {
                transform: translateX(0.5em);
            }
        }

        .icon {
            display: inline-block;

            .SvgIcon {
                width: 33px;
                height: 33px;
                margin-bottom: -10px;
                transition: transform 0.5s;

                path {
                    fill: #{colours.get-static('colour-brand-primary')};
                    fill: var(--colour-brand-primary);
                    stroke: #{colours.get-static('colour-brand-primary')};
                    stroke: var(--colour-brand-primary);
                }
            }
        }
    }
}
